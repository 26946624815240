import React, {useContext, useMemo} from 'react';

import TraitFilterContext from '../../contexts/TraitFilterContext'
import tw from "twin.macro";

const styles = {
    wrapper: {
        flexWrap: 'wrap',
        listStyle: 'none',
        gap: '0.5rem',
        padding: '0.5rem',
        margin: 0,
    },
    filter: {
        border: '1px solid grey',
        borderRadius: 2,
        padding: '0.5rem',
        cursor: 'pointer'
    }
}
const PrimaryButtonRight = tw.button`font-bold m-2 px-8 lg:px-10 py-3 rounded bg-red-600 text-gray-100 hocus:bg-red-500 focus:shadow-outline focus:outline-none transition duration-300`;
const HighlightedTextInverse = tw.span`bg-green-300 text-gray-100 px-4 transform -skew-x-12 inline-block ml-2`;
const Heading = tw.h1`font-black text-gray-900 text-3xl md:text-5xl leading-snug max-w-3xl`;
const Subheading = tw.span`text-orange-400 tracking-wider text-sm font-bold`;

const SelectedFilter = ({ name, onClick }) =>
        <li onClick={() => onClick(name)}>
            {name.split(':')[1]}
        </li>

const DefaultCurrentFiltersLayout = () => {
    const {filters, removeFilter, clearFilters} = useContext(TraitFilterContext);
    const hasFilters = useMemo(() => Object.values(filters).some(isChecked => isChecked), [filters]);

    if (!hasFilters) return <></>
    return <ul style={styles.wrapper}>
        <PrimaryButtonRight onClick={clearFilters}>Clear</PrimaryButtonRight>
        {Object.entries(filters)
            .filter(([name, isChecked]) => isChecked)
            .map(([name]) =>
                <HighlightedTextInverse>
                    <SelectedFilter
                        key={name}
                        name={name}
                        onClick={removeFilter}/>

                </HighlightedTextInverse>
            )}
    </ul>;
}

export default DefaultCurrentFiltersLayout;