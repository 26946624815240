import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import OtherMarketplaces from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";


import TwitterImage from "img/twitter-image.gif";
import DiscordImage from "img/discord-image.png";
import KnowUsBetter from "img/questions-cbits.png";
import heroImage from 'img/hero-cbits.png'
import historyImage from 'img/history-cbits.png'
import collaBitsImage from 'img/collabits.gif'
import OtherMarketplacesImage from 'img/marketplaces.png'
import {useNavigate} from "react-router-dom";

export default () => {
    const Subheading = tw.span`text-orange-400 tracking-wider text-sm`;
    const HighlightedText = tw.span`bg-gray-900 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const HighlightedTextInverse = tw.span`bg-green-300 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`text-gray-900 inline-block mt-8`;
    const imageCss = tw`rounded-2xl mx-auto`;

    return (
        <AnimationRevealPage>

            <Hero
                heading={<>Welcome to <HighlightedText>CardanoBits</HighlightedText></>}
                description={<>The first 8-bit pixel art and the second 10k collection ever launched in Cardano. Known
                    as <HighlightedText>cbits</HighlightedText>, I personally designed them to be your definitive avatar
                    .</>}
                imageSrc={heroImage}
                imageCss={imageCss}
                imageDecoratorBlob={true}
                primaryButtonRightText="Gallery"
                primaryButtonLeftText="History"
                primaryButtonLeftUrl='#social-side'
            />

            <div id="history">
                <MainFeature
                    subheading={<Subheading>Launched on Easter 2021</Subheading>}
                    heading={
                        <>
                            The first collection was released
                            <wbr/> <HighlightedTextInverse>Using Gdocs!</HighlightedTextInverse>
                        </>
                    }
                    description={
                        <Description>
                            CardanoBits were announced on <HighlightedText>March 23rd, 2021</HighlightedText>.
                            There were no Smart Contracts or Marketplaces back then, so I managed to
                            do the sale, through a Google Docs file.

                            <HighlightedText>April 4th</HighlightedText> The sale begins.
                            All they got was a google doc with a wallet address. Within just
                            49 minutes, all CBits had sold out. With ~600 requests/sec,
                            servers of Yoroi wallet crashed.

                            <HighlightedText>April 5th</HighlightedText> People are waiting
                            for the CBits to be sent out. There was just one problem though.
                            The lack of standarised metadata schema led to a decision to
                            burn all already minted CBits. Updated schema was published shortly
                            after.

                            <HighlightedText>April 7th</HighlightedText> All of the original
                            mints were burned and refunds (to everyone that paid too late)
                            were issued. Final minting begins.

                            <HighlightedText>April 10th</HighlightedText> 5 days after the
                            sale the new tweet reads: "Ok, it's happening.". Everyone that
                            was lucky enough to get the payment through, received their CBits.
                            (Special thanks to Matt Jura for this awesome recopilation)
                        </Description>
                    }
                    buttonRounded={false}
                    textOnLeft={false}
                    primaryButtonText="Check the OG Collection"
                    primaryButtonUrl="/#gallery"
                    imageSrc={historyImage}
                    imageCss={imageCss}
                    imageDecoratorBlob={true}
                    imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
                />
            </div>
            <div id="social-side">
                <Features

                    subheading={<>Social side</>}
                    heading={
                        <>
                            Join our <HighlightedText>community</HighlightedText>
                        </>
                    }
                    description={
                        <>
                            Check and follow us on the socials, so you are up to date with the latest news happening
                            on
                            the
                            #cbits world.
                        </>
                    }
                    cards={[
                        {
                            imageSrc: TwitterImage,
                            title: "Twitter",
                            description: "Managed directly by the creator, meant to announce news and talk with other people or projects publicly",
                            url: "https://twitter.com/cardanobits"
                        },
                        {
                            imageSrc: DiscordImage,
                            title: "Discord",
                            description: "The best place to engage with other members of the community. Earn prices, know People and enjoy! ",
                            url: "https://discord.gg/MuH8dzjtD4"
                        }
                    ]}

                    imageContainerCss={tw`p-2!`}
                    imageCss={tw`w-20! h-20!`}
                />
            </div>
            <div id="collabits">
                <MainFeature2
                    subheading={<Subheading>The first mega-collaborative event</Subheading>}
                    heading={<>What was the <HighlightedText>CollaBits</HighlightedText> event?</>}
                    description="In those early days, Artists were popping more and more, but I felt
        the space needed more alliances. So with Tyler (withspaces), we put together
         event, in which each Artist was supposed to upload two submissions of #cbits made
         in their style. The event was a whole week of auctions of the 1/1s tributes
        , made through the escrow service (before smart contracts) of the CNFT Discord.
         Launched in June 2021."
                    statistics={[
                        {
                            key: "Artists",
                            value: "50",
                        },
                        {
                            key: "Unique pieces",
                            value: "100"
                        },
                        {
                            key: "Days of auctions",
                            value: "7"
                        }
                    ]}
                    primaryButtonText="Explore the collection"
                    primaryButtonUrl="https://www.jpg.store/collection/collabits"
                    imageInsideDiv={false}
                    imageSrc={collaBitsImage}
                    imageCss={Object.assign(tw`bg-cover`, imageCss)}
                    imageContainerCss={tw`md:w-1/2 h-auto`}
                    imageDecoratorBlob={true}
                    imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
                    textOnLeft={true}
                />
            </div>
            <div id="faq">
                <FAQ
                    imageSrc={KnowUsBetter}
                    imageShadow={false}
                    subheading="FAQs"
                    heading={
                        <>
                            Technical <span tw="text-red-500">Stuff</span>
                        </>
                    }
                    description="Here you have some important bullets about the collection, from a tech perspective. We´ve include information both from cbits and collabits"
                    faqs={[
                        {
                            question: "How can I know if I have an original cbit?",
                            answer:
                                "Easy, you can check the time-locked policy, the one from your cbit should be exactly this: 1131301ad4b3cb7deaddbc8f03f77189082a5738c0167e1772233097. You can verify using https://cardanoscan.io/."
                        },
                        {
                            question: "How can I know if I have an original collabit?",
                            answer:
                                "Same as cbits, CollaBits has a time-locked policy too, the one from your collabit should be exactly this: 400a73ab09fb2ea5af3df1bac589599803ae53c18bc583f28698c13c."
                        },
                        {
                            question: "How did you create the cbits art?",
                            answer:
                                "Well, I designed trait by trait using pixel art and an specific color schema. After, using Python, I made an algorithm which randomly created all the combinations."
                        },
                        {
                            question: "Where does the data live?",
                            answer:
                                "The whole metadata of each cbit or collabit, lives in the Cardano Blockchain. The source images files, are stored in IPFS (Interplanetary File System), which allows you to store files, getting a unique ID which prevents anyone from making a change on the images and keeping track of them in a distributed network"
                        }
                    ]}
                />
            </div>
            <OtherMarketplaces
                subheading={<>Where can I get more CNFTs?</>}
                text={<>Check the best marketplaces on Cardano, to trade your favorite NFTs
                    and <HighlightedTextInverse>CBITS</HighlightedTextInverse></>}
                link1Text="Jpg Store"
                link1Url="https://www.jpg.store/collection/cardanobits"
                link1IconSrc="https://static.jpgstoreapis.com/icons/logo-light.svg"
                link2Text=""
                link2Url=""
                link2IconSrc=""
                imageSrc={OtherMarketplacesImage}
            />
            <Footer/>
        </AnimationRevealPage>
    );

}
