import React, { useCallback, useContext, useMemo } from 'react';

import TraitFilterContext from './../../contexts/TraitFilterContext/TraitFilterContext';
import tw from "twin.macro";

const TraitFilter = ({ traitName, variationNames, getCount, onChange, isChecked }) => {
  const getKeyName = (traitName, variationName) => `${traitName}:${variationName}`

  const styles = {
    traitHeader: {
      paddingTop: '0.75rem',
      paddingLeft:'0.10rem',
      paddingBottom: '0.75rem',
      textAlign: 'left',
      textTransform: 'uppercase', // Convierte el texto a mayúsculas
      color: '#090505', // Un color distintivo
      backgroundColor: '#e800ff', // Color de fondo para el encabezado
      borderRadius:'1em'
    },
    variationNames: {
      textAlign: 'left'
    },
    variationName: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between'
    }
  }
  const Heading = tw.h1`font-black text-gray-900 text-3xl md:text-5xl leading-snug max-w-3xl`;
  const Subheading = tw.span`text-orange-400 tracking-wider text-sm font-bold`;
  const HighlightedText = tw.span`bg-gray-900 text-gray-100 px-4 transform -skew-x-12 inline-block m-2 ml-6`;
  const HighlightedTextInverse = tw.span`bg-green-300 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`text-gray-900 mt-8`;
  const imageCss = tw`rounded-2xl mx-auto`;

  return (
    <div>
      <HighlightedText >
        {traitName}
      </HighlightedText>
      <Description>
        {(variationNames || [])
          .sort((variationNameA, variationNameB) =>
            getCount(getKeyName(traitName, variationNameB)) - getCount(getKeyName(traitName, variationNameA)))
          .map((variationName) => {
            const keyName = getKeyName(traitName, variationName);
            return (
              <label
                key={keyName}
                style={styles.variationName}
                name={keyName}>
                <div>
                  <input
                    name={keyName}
                    type="checkbox"
                    checked={isChecked(keyName) || false}
                    onChange={onChange}
                    style={{
                      marginRight: '0.5rem',
                      accentColor:'#65a30d'
                    }}
                  />
                  <span>{variationName}</span>
                </div>

                <Subheading >{getCount(keyName)}</Subheading>
              </label>
            )
          })}
      </Description>
    </div>
  )
}

const TraitFilters = ({ variationNamesByTraitName, ...otherProps }) => {
  const entries = useMemo(() =>
    Object.entries(variationNamesByTraitName)
      .sort((a, b) => a[0].localeCompare(b[0])),
    [variationNamesByTraitName]
  )

  return entries.map(([traitName, variationNames]) =>
    <TraitFilter
      key={traitName}
      traitName={traitName}
      variationNames={variationNames}
      {...otherProps} />
  )
}

const DefaultFiltersLayout = () => {
  const { variationNamesByTraitName, tokenCountByVariationName, filters, addFilter, removeFilter } = useContext(TraitFilterContext);
  const getCount = useCallback((key) => tokenCountByVariationName[key], [tokenCountByVariationName]);
  const isChecked = useCallback((key) => filters[key], [filters]);
  const onChange = useCallback(({ target: { name, checked } }) => {
    if (checked) {
      addFilter(name)
    } else {
      removeFilter(name)
    }
  }, [addFilter, removeFilter]);

  return <TraitFilters
    variationNamesByTraitName={variationNamesByTraitName}
    getCount={getCount}
    onChange={onChange}
    isChecked={isChecked} />
}

export default DefaultFiltersLayout;
