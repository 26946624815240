import React, { useContext, useState, useEffect } from 'react';
import TraitFilterContext from '../../contexts/TraitFilterContext';
import tw from "twin.macro";

const styles = {
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        overflow: 'hidden',
    },
    burgerMenu: {
        position: 'fixed',
        top: '10px',
        left: '10px',
        zIndex: 1001, // Asegurar que esté por encima del menú lateral
        cursor: 'pointer',
        display: 'none', // Oculto por defecto, se muestra en móvil
    },
    leftColumn: {
        minWidth: '300px',
        width: '300px',
        height: '100%',
        padding: '20px',
        backgroundColor: '#f0f0f0',
        overflowY: 'auto',
        transition: 'transform 0.3s ease-in-out',
        position: 'fixed',
        top: 0,
        left: '-300px', // Oculto fuera de la pantalla
        zIndex: 1000,
    },
    rightColumn: {
        flexGrow: 1,
        height: '100%',
        padding: '40px',
        backgroundColor: '#ffffff',
        marginLeft: '0', // Sin margen por defecto, se ajusta según el estado del menú
        transition: 'margin-left 0.3s ease-in-out',
    },
    menuBar: {
        width: '30px',
        height: '25px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    menuBarLine: {
        width: '100%',
        height: '3px',
        backgroundColor: '#333',
    },

};
const Heading = tw.h1`font-black text-gray-900 text-3xl md:text-5xl leading-snug max-w-3xl`;

const filterStyles = {
    traitType: {
        textTransform: 'uppercase', // Convierte el texto a mayúsculas
        color: '#FF4500', // Un color distintivo
        // Puedes agregar más estilos aquí si lo necesitas
    }
};
const DefaultGalleryLayout = ({ FiltersLayout, TokensLayout, CurrentFiltersLayout }) => {
    const { tokens, filteredTokens } = useContext(TraitFilterContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <div style={styles.wrapper}>
            {!isDesktop && (
                <div style={{...styles.burgerMenu, display: 'block'}} onClick={toggleMenu}>
                    <div style={styles.menuBar}>
                        <div style={styles.menuBarLine}></div>
                        <div style={styles.menuBarLine}></div>
                        <div style={styles.menuBarLine}></div>
                    </div>
                </div>
            )}

            <div style={{...styles.leftColumn, left: isMenuOpen || isDesktop ? '0' : '-300px'}}>
                <FiltersLayout/>
            </div>

            <div style={{...styles.rightColumn, marginLeft: isDesktop || isMenuOpen ? '300px' : '0'}}>
                <Heading >
                    Matching cbits : {`${filteredTokens.length}/${tokens.length}`}
                </Heading>
                <br/>

                <CurrentFiltersLayout/>

                <TokensLayout/>


            </div>

        </div>
    );
};

export default DefaultGalleryLayout;
