import React from "react";

import CollectionGallery from "../components/CollectionGallery";
import tokens from "images/cbits.json";
import AnimationRevealPage from "../helpers/AnimationRevealPage";
import tw from "twin.macro";
export default () => {
    return (
        <CollectionGallery
            tokens={tokens}
        />
    );
};
