import React, { Fragment, useContext, useState } from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeGrid } from 'react-window';
import TraitFilterContext from '../../contexts/TraitFilterContext';
import tw from "twin.macro";
const HighlightedText = tw.span`bg-gray-900 text-gray-100 px-4 transform -skew-x-12 inline-block m-2 ml-6`;
const Description = tw.span`text-gray-100 mt-8`;
const HighlightedTextInverse = tw.span`bg-green-300 text-gray-100 px-4 transform -skew-x-12 inline-block`;

const TokenImage = ({ width, token, onClick, showTokenName = false }) => {
  const styles = {
    image: {
      borderRadius: '0.5rem'
    },
    attributes: {
      padding: '0 0 0 2rem'
    },
    traitTypeStyle: {
      textTransform: 'uppercase',
      color: '#FF4500', // Un color distintivo, puedes cambiarlo a tu gusto
      fontWeight: 'bold', // Opcional, para mayor énfasis
    },
    wrapper: {
      hover:'scale-110',

      position: 'relative',
      display: 'inline-block'
    },
    tokeName: {
      bottom: '0.0rem',
      display: 'flex',
      justifyContent: 'center'

    }
  }

  return (
    <div style={styles.wrapper}>


      <img
        width={width}
        height="auto"
        src={images[token.name+'.png']}
        alt={token.name}
        crossOrigin="anonymous"
        {...(onClick
          ? {
            onClick,
            style: {
              hover:'bg-sky-700',

              ...styles.image,
              cursor: 'pointer'
            }
          }
          : {
            style: {
              hover:'bg-sky-700',
              ...styles.image
            }
          })} />

      {showTokenName && <HighlightedText style={styles.tokeName}>
        #{token.name}
      </HighlightedText>}
    </div>
  )
}

function importAll(r) {
  let images = {};
  r.keys().map((item) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../../images/cbits_images', false, /\.(png|jpe?g|svg)$/));


const BasicModal = ({ imageWidth, token, onClose }) => {
  const styles = {
    modal: {
      position: 'fixed',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 9999,
      background: 'rgb(25 25 25 / 80%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    content: {
      borderRadius: 10,
      padding: '3rem',
      paddingTop: '2rem',
      background: '#111111'
    },
    attributes: {
      padding: '0 0 0 2rem'
    }
  }
  return (
    <div style={styles.modal}>
      <div style={styles.content}>
        <div style={{textAlign: 'right', paddingBottom: '0.5rem'}}>
          <a href="#gallery" style={{textDecoration: 'none', color: 'white', fontWeight: 'bold'}} onClick={onClose}>X</a>
        </div>
        <Description style={{textAlign: 'left'}}>#{token.name}</Description>
        <br/><br/>
        <div>
          <TokenImage
              width={imageWidth}
              token={token}/>
          <br/>

          <br/>
          <Description>
            <HighlightedTextInverse>Attributes</HighlightedTextInverse>
            <ul style={{listStyle: 'none', padding: 0}}>
              {token.attributes.map(({trait_type, value}) =>
                  <li key={`${trait_type}:${value}`}>
                    {trait_type} - {value}
                  </li>)}
            </ul>
          </Description>
        </div>
      </div>
    </div >
  )
}

const DefaultTokensLayout = () => {
  const ROW_GUTTER = 20;
  const COLUMN_GUTTER = 20;
  const CARD_WIDTH = 250;
  const CARD_HEIGHT = 290;

  const { filteredTokens } = useContext(TraitFilterContext)
  const [selectedToken, setSelectedToken] = useState({});
  const [open, setOpen] = useState(false);
  const showModal = () => setOpen(true);
  const hideModal = () => setOpen(false);

  const onSelect = (token) => {
    showModal();
    setSelectedToken(token);
  }

  return (
    <Fragment>
      {open && <BasicModal imageWidth={CARD_WIDTH} token={selectedToken} onClose={hideModal} />}
      {<AutoSizer>
        {({ height, width }) => {
          const columnCount = Math.floor(width / (CARD_WIDTH + COLUMN_GUTTER));
          const rowCount = Math.ceil(filteredTokens.length / columnCount);

          return (
            <FixedSizeGrid
              columnCount={columnCount}
              columnWidth={width / columnCount}
              height={height}
              rowCount={rowCount}
              rowHeight={CARD_HEIGHT + ROW_GUTTER}
              width={width}
            >
              {({ columnIndex, rowIndex, style }) => {
                const token = filteredTokens[rowIndex * columnCount + columnIndex];

                if (!token) {
                  return null;
                }

                return (
                  <div  style={style}>
                    <TokenImage
                      key={token.name}
                      width={CARD_WIDTH}
                      token={token}
                      onClick={() => onSelect(token)}
                      showTokenName={true} />
                  </div>
                );
              }}
            </FixedSizeGrid>
          );
        }}
      </AutoSizer>}
    </Fragment>
  )
}
export default DefaultTokensLayout;