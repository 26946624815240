import React, { useEffect } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import {HashRouter as Router, Routes, Route, useNavigate} from "react-router-dom";
import RestaurantLandingPage from "./demos/RestaurantLandingPage";
import Gallery from "./demos/Gallery";
import ComponentRenderer from "ComponentRenderer.js";

export default function App() {



    return (
        <>
            <GlobalStyles />
            <Router>
                <Routes>
                    <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
                    <Route path="/components/:type/:name" element={<ComponentRenderer />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/" element={<RestaurantLandingPage />} />
                </Routes>
            </Router>
        </>
    );
}
